import axios from "@/plugins/axios";

const urlPath = "/platform/api/v1/agencies/";

const getAgencies = (params) => {
  return axios.get(`${urlPath}?${params}`);
};

const createAgency = (data) => {
  return axios.post(urlPath, data);
};

const getAgency = (id) => {
  return axios.get(`${urlPath}${id}`);
};

const activateAgency = (id) => {
  return axios.post(`${urlPath}${id}/activate`);
};

const deactivateAgency = (id) => {
  return axios.post(`${urlPath}${id}/deactivate`);
};

const archiveAgency = (id) => {
  return axios.delete(`${urlPath}${id}`);
};

const updateAgency = ({ id, data }) => {
  return axios.put(`${urlPath}${id}`, data);
};

const getAgenciesAgentList = ({ id, params }) => {
  return axios.get(`${urlPath}${id}/agents/?${params}`);
};

const createAgenciesAgent = ({ id, data }) => {
  return axios.post(`${urlPath}${id}/agents/`, data);
};

const getAgenciesAgent = ({ agencyId, agentId }) => {
  return axios.get(`${urlPath}${agencyId}/agents/${agentId}`);
};

const editAgenciesAgent = ({ agencyId, agentId, data }) => {
  return axios.put(`${urlPath}${agencyId}/agents/${agentId}`, data);
};

const removeAgenciesAgent = ({ agencyId, agentId }) => {
  return axios.delete(`${urlPath}${agencyId}/agents/${agentId}`);
};

const activateAgenciesAgent = ({ agencyId, agentId }) => {
  return axios.post(`${urlPath}${agencyId}/agents/${agentId}/activate`);
};

const deactivateAgenciesAgent = ({ agencyId, agentId }) => {
  return axios.post(`${urlPath}${agencyId}/agents/${agentId}/deactivate`);
};

const updateAgenciesSite = ({ agencyId, data }) => {
  return axios.post(`${urlPath}${agencyId}/site`, data);
}

const getAgencyDomainUpdateRequests = (params) => {
  return axios.get(`/platform/api/v1/agency-domain-update-requests/`, { params });
}

const updateAgencyDomainUpdateRequest = ({ number, data }) => {
  return axios.post(`/platform/api/v1/agency-domain-update-requests/${number}/action`, data);
}

export default {
  getAgencies,
  createAgency,
  getAgency,
  activateAgency,
  deactivateAgency,
  archiveAgency,
  updateAgency,
  getAgenciesAgentList,
  createAgenciesAgent,
  getAgenciesAgent,
  editAgenciesAgent,
  removeAgenciesAgent,
  activateAgenciesAgent,
  deactivateAgenciesAgent,
  updateAgenciesSite,
  getAgencyDomainUpdateRequests,
  updateAgencyDomainUpdateRequest
};
