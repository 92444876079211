import agencyApi from "@/api/agency";
import { i18n } from "@/plugins/i18n";

const state = {
  isLoading: false,
  agencies: [],
  agency: {},
  errors: [],
  domainUpdateRequests: {},
  searchValue: "",
  isSubmitting: false,
  isActivating: false,
  isDeactivating: false,
  isArchiving: false,
  isUpdateAgenciesSite: false,
  isGetAgencyDomainUpdateRequests: false,
  isUpdateAgencyDomainUpdateRequest: false
};

export const mutationTypes = {
  getAgenciesStart: "[agency] getAgenciesStart",
  getAgenciesFailed: "[agency] getAgenciesFailed",
  getAgenciesSuccess: "[agency] getAgenciesSuccess",
  changeSearchValue: "[agency] changeSearchValue",

  createAgencyStart: "[agency] createAgencyStart",
  createAgencyFailed: "[agency] createAgencyFailed",
  createAgencySuccess: "[agency] createAgencySuccess",

  getAgencyStart: "[agency] getAgencyStart",
  getAgencyFailed: "[agency] getAgencyFailed",
  getAgencySuccess: "[agency] getAgencySuccess",

  activateStart: "[agency] activateStart",
  activateFailed: "[agency] activateFailed",
  activateSuccess: "[agency] activateSuccess",
  deactivateStart: "[agency] deactivateStart",
  deactivateFailed: "[agency] deactivateFailed",
  deactivateSuccess: "[agency] deactivateSuccess",

  archiveStart: "[agency] archiveStart",
  archiveFailed: "[agency] archiveFailed",
  archiveSuccess: "[agency] archiveSuccess",

  updateAgencyStart: "[agency] updateAgencyStart",
  updateAgencyFailed: "[agency] updateAgencyFailed",
  updateAgencySuccess: "[agency] updateAgencySuccess",

  updateAgenciesSiteStart: "[agency] updateAgenciesSiteStart",
  updateAgenciesSiteFailed: "[agency] updateAgenciesSiteFailed",
  updateAgenciesSiteSuccess: "[agency] updateAgenciesSiteSuccess",

  getAgencyDomainUpdateRequests: "[agency] getAgencyDomainUpdateRequests",
  getAgencyDomainUpdateRequestsSuccess: "[agency] getAgencyDomainUpdateRequestsSuccess",
  getAgencyDomainUpdateRequestsFailed: "[agency] getAgencyDomainUpdateRequestsFailed",

  updateAgencyDomainUpdateRequest: "[agency] updateAgencyDomainUpdateRequest",
  updateAgencyDomainUpdateRequestSuccess: "[agency] updateAgencyDomainUpdateRequestSuccess",
  updateAgencyDomainUpdateRequestFailed: "[agency] updateAgencyDomainUpdateRequestFailed",

  resetErrors: "[agency] resetErrors",
};

const mutations = {
  [mutationTypes.resetErrors](state) {
    state.errors = [];
  },
  [mutationTypes.changeSearchValue](state, payload) {
    state.searchValue = payload;
  },
  [mutationTypes.getAgenciesStart](state) {
    state.isLoading = true;
    state.errors = [];
  },
  [mutationTypes.getAgenciesSuccess](state, payload) {
    state.isLoading = false;
    state.errors = [];
    state.agencies = payload;
  },
  [mutationTypes.getAgenciesFailed](state, payload) {
    state.isLoading = false;
    state.errors = payload;
  },

  [mutationTypes.createAgencyStart](state) {
    state.isSubmitting = true;
    state.errors = [];
  },
  [mutationTypes.createAgencySuccess](state) {
    state.isSubmitting = false;
    state.errors = [];
  },
  [mutationTypes.createAgencyFailed](state, payload) {
    state.isSubmitting = false;
    state.errors = payload;
  },

  [mutationTypes.getAgencyStart](state) {
    state.isLoading = true;
    state.errors = [];
  },
  [mutationTypes.getAgencySuccess](state, payload) {
    state.isLoading = false;
    state.errors = [];
    state.agency = payload;
  },
  [mutationTypes.getAgencyFailed](state, payload) {
    state.isLoading = false;
    state.errors = payload;
  },

  [mutationTypes.activateStart](state) {
    state.isActivating = true;
    state.errors = [];
  },
  [mutationTypes.activateSuccess](state, payload) {
    state.isActivating = false;
    state.agency = payload;
    state.errors = [];
  },
  [mutationTypes.activateFailed](state, payload) {
    state.isActivating = false;
    state.errors = payload;
  },
  [mutationTypes.deactivateStart](state) {
    state.isDeactivating = true;
    state.errors = [];
  },
  [mutationTypes.deactivateSuccess](state, payload) {
    state.isDeactivating = false;
    state.agency = payload;
    state.errors = [];
  },
  [mutationTypes.deactivateFailed](state, payload) {
    state.isDeactivating = false;
    state.errors = payload;
  },

  [mutationTypes.archiveStart](state) {
    state.isArchiving = true;
    state.errors = [];
  },
  [mutationTypes.archiveSuccess](state) {
    state.isArchiving = false;
    state.errors = [];
  },
  [mutationTypes.archiveFailed](state, payload) {
    state.isArchiving = false;
    state.errors = payload;
  },

  [mutationTypes.updateAgencyStart](state) {
    state.isSubmitting = true;
    state.errors = [];
  },
  [mutationTypes.updateAgencySuccess](state, payload) {
    state.isSubmitting = false;
    state.agency = payload;
    state.errors = [];
  },
  [mutationTypes.updateAgencyFailed](state, payload) {
    state.isSubmitting = false;
    state.errors = payload;
  },

  [mutationTypes.updateAgenciesSiteStart](state) {
    state.isUpdateAgenciesSite = true;
    state.errors = [];
  },
  [mutationTypes.updateAgenciesSiteSuccess](state, payload) {
    state.isUpdateAgenciesSite = false;
    state.agency = payload;
    state.errors = [];
  },
  [mutationTypes.updateAgenciesSiteFailed](state, payload) {
    state.isUpdateAgenciesSite = false;
    state.errors = payload;
  },

  [mutationTypes.getAgencyDomainUpdateRequests](state) {
    state.isGetAgencyDomainUpdateRequests = true;
    state.errors = [];
  },

  [mutationTypes.getAgencyDomainUpdateRequestsSuccess](state, payload) {
    state.isGetAgencyDomainUpdateRequests = false;
    state.errors = [];
    state.domainUpdateRequests = payload;
  //   state.domainUpdateRequests = {
  //     "pages": 1,
  //     "total": 0,
  //     "page": 0,
  //     "results": [
  //       {
  //         "number": "A-1000000",
  //         "domain_type": "agency",
  //         "new_domain": "example.com",
  //         "status": "submitted"
  //       }
  //     ]
  //   }
  },

  [mutationTypes.getAgencyDomainUpdateRequestsFailed](state, payload) {
    state.isGetAgencyDomainUpdateRequests = false;
    state.errors = payload;
  },

  [mutationTypes.updateAgencyDomainUpdateRequest](state) {
    state.isUpdateAgencyDomainUpdateRequest = true;
    state.errors = [];
  },

  [mutationTypes.updateAgencyDomainUpdateRequestSuccess](state, payload) {
    state.isUpdateAgencyDomainUpdateRequest = false;
    state.errors = [];
  },

  [mutationTypes.updateAgencyDomainUpdateRequestFailed](state, payload) {
    state.isUpdateAgencyDomainUpdateRequest = false;
    state.errors = payload;
  }
};

export const actionTypes = {
  getAgencies: "[agency] getAgencies",
  getAgency: "[agency] getAgency",
  createAgency: "[agency] createAgency",
  activateAgency: "[agency] activateAgency",
  deactivateAgency: "[agency] deactivateAgency",
  archiveAgency: "[agency] archiveAgency",
  updateAgency: "[agency] updateAgency",
  updateAgenciesSite: "[agency] updateAgenciesSite",
  getAgencyDomainUpdateRequests: "[agency] getAgencyDomainUpdateRequests",
  updateAgencyDomainUpdateRequest: "[agency] updateAgencyDomainUpdateRequest"
};

const actions = {
  async [actionTypes.getAgencies](context, payload) {
    try {
      context.commit(mutationTypes.getAgenciesStart);
      const res = await agencyApi.getAgencies(payload);
      context.commit(mutationTypes.getAgenciesSuccess, res.data);
      return true;
    } catch (e) {
      context.commit(mutationTypes.getAgenciesFailed, e.response.data.error);
      return false;
    }
  },

  async [actionTypes.createAgency](context, payload) {
    try {
      context.commit(mutationTypes.createAgencyStart);
      const res = await agencyApi.createAgency(payload);
      context.commit(mutationTypes.createAgencySuccess);
      return res.data.number;
    } catch (e) {
      context.commit(mutationTypes.createAgencyFailed, e.response.data.error);
      return false;
    }
  },

  async [actionTypes.getAgency](context, payload) {
    try {
      context.commit(mutationTypes.getAgencyStart);
      const res = await agencyApi.getAgency(payload);
      context.commit(mutationTypes.getAgencySuccess, res.data);
      return true;
    } catch (e) {
      context.commit(mutationTypes.getAgencyFailed, e.response.data.error);
      return false;
    }
  },

  async [actionTypes.activateAgency](context, payload) {
    try {
      context.commit(mutationTypes.activateStart);

      const res = await agencyApi.activateAgency(payload);
      context.commit(mutationTypes.activateSuccess, res.data);
      return true;
    } catch (e) {
      context.commit(mutationTypes.activateFailed, e.response.data.error);
      return false;
    }
  },
  async [actionTypes.deactivateAgency](context, payload) {
    try {
      context.commit(mutationTypes.deactivateStart);

      const res = await agencyApi.deactivateAgency(payload);
      context.commit(mutationTypes.deactivateSuccess, res.data);
      return true;
    } catch (e) {
      context.commit(mutationTypes.deactivateFailed, e.response.data.error);
      return false;
    }
  },

  async [actionTypes.archiveAgency](context, payload) {
    try {
      context.commit(mutationTypes.archiveStart);

      await agencyApi.archiveAgency(payload);
      context.commit(mutationTypes.archiveSuccess);
      return true;
    } catch (e) {
      context.commit(mutationTypes.archiveFailed, e.response.data.error);
      return false;
    }
  },

  async [actionTypes.updateAgency](context, payload) {
    try {
      context.commit(mutationTypes.updateAgencyStart);

      const res = await agencyApi.updateAgency(payload);
      context.commit(mutationTypes.updateAgencySuccess, res.data);
      return true;
    } catch (e) {
      context.commit(mutationTypes.updateAgencyFailed, e.response.data.error);
      return false;
    }
  },

  async [actionTypes.updateAgenciesSite](context, payload) {
    try {
      context.commit(mutationTypes.updateAgenciesSiteStart);

      const res = await agencyApi.updateAgenciesSite(payload);
      context.commit(mutationTypes.updateAgenciesSiteSuccess, res.data);
      return true;
    } catch (e) {
      context.commit(mutationTypes.updateAgenciesSiteFailed, e.response.data.error);
      return false;
    }
  },

  async [actionTypes.getAgencyDomainUpdateRequests](context, payload) {
    try {
      context.commit(mutationTypes.getAgencyDomainUpdateRequests);
      const res = await agencyApi.getAgencyDomainUpdateRequests(payload);
      context.commit(mutationTypes.getAgencyDomainUpdateRequestsSuccess, res.data);
      return true;
    } catch (e) {
      context.commit(mutationTypes.getAgencyDomainUpdateRequestsFailed, e.response.data.error);
      return false;
    }
  },

  async [actionTypes.updateAgencyDomainUpdateRequest](context, payload) {
    try {
      context.commit(mutationTypes.updateAgencyDomainUpdateRequest);
      await agencyApi.updateAgencyDomainUpdateRequest(payload);
      context.commit(mutationTypes.updateAgencyDomainUpdateRequestSuccess);
      return true;
    } catch (e) {
      context.commit(mutationTypes.updateAgencyDomainUpdateRequestFailed, e.response.data.error);
      return false;
    }
  }
};

export const getterTypes = {
  numberErrors: "[agency] numberErrors",
  titleErrors: "[agency] titleErrors",
  isArchived: "[agency] isArchived",
  prefixErrors: "[agency] prefixErrors",
  licenseErrors: "[agency] licenseErrors"
};

const getters = {
  [getterTypes.isArchived](state) {
    return state.agency.status === "archived";
  },
  [getterTypes.titleErrors]({ errors }) {
    const result = [];
    errors.find((i) => i == "title__required") &&
      result.push(i18n.t("errors.titleRequired"));
    errors.find((i) => i == "title__invalid") &&
      result.push(i18n.t("errors.titleInvalid"));
    return result;
  },
  [getterTypes.numberErrors]({ errors }) {
    const result = [];
    errors.find((i) => i == "cr_number__required") &&
      result.push(i18n.t("errors.crNumberRequired"));
    errors.find((i) => i == "cr_number__invalid") &&
      result.push(i18n.t("errors.crNumberInvalid"));
    errors.find((i) => i == "cr_number__exists") &&
      result.push(i18n.t("errors.crNumberExists"));
    return result;
  },
  [getterTypes.prefixErrors]({ errors }) {
    const result = [];
    errors.find((i) => i == "prefix__required") &&
      result.push(i18n.t("errors.prefixRequired"));
    errors.find((i) => i == "prefix__invalid") &&
      result.push(i18n.t("errors.prefixInvalid"));
    errors.find((i) => i == "prefix__exists") &&
      result.push(i18n.t("errors.prefixExists"));
    return result;
  },
  [getterTypes.licenseErrors]({ errors }) {
    const result = [];
    errors.find((i) => i == "nhc_license_id__required") &&
      result.push(i18n.t("errors.nhc_license_id__required"));
    errors.find((i) => i == "nhc_license_id__invalid") &&
    result.push(i18n.t("errors.nhc_license_id__invalid"));
    errors.find((i) => i == "nhc_advertiser_id__required") &&
      result.push(i18n.t("errors.nhc_advertiser_id__required"));
    errors.find((i) => i == "nhc_advertiser_id__invalid") &&
    result.push(i18n.t("errors.nhc_advertiser_id__invalid"));
    return result;
  }
};

export default {
  state,
  mutations,
  actions,
  getters,
};
