<template>
  <v-app>
    <component :is="layout" :key="key">
      <router-view />
    </component>
  </v-app>
</template>

<script>
import EmptyLayout from "@/layouts/EmptyLayout";
import MainLayout from "@/layouts/MainLayout";

export default {
  components: { EmptyLayout, MainLayout },
  data() {
    return {
      key: 1,
    };
  },
  watch: {
    lang() {
      ++this.key;
    },
  },
  computed: {
    layout() {
      return (this.$route.meta.layout || "empty") + "-layout";
    },
    lang() {
      return this.$i18n.locale;
    },
  },
};
</script>

<style lang="scss">
@import "chartist/dist/scss/chartist.scss";

#app {
  * {
    font-family: "Frutiger", sans-serif !important;
  }
  .arabic-black {
    font-family: "Frutiger Black", sans-serif !important;
  }
  .arabic-bold {
    font-family: "Frutiger Bold", sans-serif !important;
  }
  .arabic-light {
    font-family: "Frutiger Light", sans-serif !important;
  }
  .arabic-default {
    font-family: "Frutiger", sans-serif !important;
  }

  .v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state)
    > .v-input__control
    > .v-input__slot
    fieldset {
    color: #e5e5e5;
  }

  .v-text-field--enclosed.v-input--dense:not(.v-text-field--solo).v-text-field--outlined .v-input__prepend-inner {
    margin-top: 10px !important;
  }

  .v-input:not(.search) {
    // .v-input__append-inner {
    //   margin-right: 8px;
    // }
    .v-input__slot {
      height: 54px;
      min-height: 54px;
      border-radius: 12px !important;
      border-top-left-radius: 12px !important;
      border-top-right-radius: 12px !important;
      border-bottom-right-radius: 12px !important;
      border-bottom-left-radius: 12px !important;
    }
  }

  .v-input.input50 {
    .v-input__slot {
      height: 50px;
      min-height: 50px;
    }
  }

  .v-input.search {
    .v-input__slot {
      height: 48px;
      min-height: 48px;
    }
    .v-input__prepend-inner {
      margin-top: 12px !important;
    }
  }

  .v-data-table {
    border: 1px solid var(--v-gray-lighten2);
    border-radius: 0px;
    margin: 0 auto;
    overflow: hidden;
    thead {
      tr {
        th {
          color: #9d9d9d;
          background-color: #f4f4f4 !important;
          text-transform: uppercase;
          vertical-align: middle;
          font-size: 14px;
          padding-left: 12px;
          padding-right: 12px;
          .v-input {
            margin: 0 !important;
            padding: 0 !important;
          }
        }
      }
    }
    tbody {
      tr {
        cursor: pointer;
        &.cursor--default {
          cursor: default;
        }
      }
      td {
        font-size: 15px;
        vertical-align: middle;
        padding-left: 12px;
        padding-right: 12px;
        .v-input {
          margin: 0 !important;
          padding: 0 !important;
        }
      }
    }
  }
  .v-text-field__details {
    margin: 4px 0;
    .v-messages__message {
      min-height: 14px;
      height: initial !important;
    }
  }
}

// .header-checkbox .v-icon {
//   background-color: #7ab845 !important;
// }
.Vue-Toastification__toast-component-body {
  display: flex;
  align-items: center;
}

.Vue-Toastification__container.top-right {
  top: 70px !important;
}

.Vue-Toastification__toast--default {
  cursor: pointer;
}

label {
  display: block;
  margin-bottom: 2px !important;
}
.v-messages__message {
  height: 26px !important;
}

.v-sheet.v-card:not(.v-sheet--outlined) {
  box-shadow: none !important;
}

.v-data-footer {
  margin: 0 !important;
  padding: 0 12px !important;
  .v-input__append-inner {
    position: absolute !important;
    margin-left: 20px !important;
  }
}

.v-data-table__wrapper {
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}
.v-btn {
  letter-spacing: 0 !important;
}

.info__section--unset {
  overflow: unset !important;
}

.table {
  &--fixed {
    border-radius: 0 !important;
    position: relative;
    border-bottom-left-radius: 16px !important;
    border-bottom-right-radius: 16px !important;
  }

  &__empty-block {
    position: absolute;
    left: 50%;
    top: calc(50% + 24px);
    transform: translate(-50%, -50%);
  }
}

.card--rejected {
  border: 1px solid #c5222b !important;
  background-color: #f1d4d5 !important;
}

.v-dialog {
  border-radius: 16px !important;
}

.fieldset {
  max-width: 292px;
  width: 100%;

  &--medium {
    max-width: 316px;
    width: 100%;
  }
  &--long {
    max-width: 400px;
    width: 100%;
  }
  &--double {
    max-width: 816px;
    width: 100%;
  }
}

.main__wrapper {
  //max-width: 1340px;
  width: 100%;
  padding: 0 32px;
  margin: 0 auto;
}

.info__section {
  border: 1px solid #e5e5e5;
  box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.06);
  border-radius: 16px;
  background: white;
  overflow: hidden;
  &-item {
    display: flex;
    padding: 8px;
    div:first-of-type {
      font-weight: 700;
      width: 200px;
    }
    div:last-of-type {
      color: var(--v-gray-1-base);
    }
  }
  &-item:nth-child(even) {
    background: var(--v-gray-4-base);
  }
}
.close-btn {
  position: absolute !important;
  top: 16px;
  right: 16px;
  z-index: 10;
}

.radius-34 {
  border-radius: 34px !important;
}
.radius-24 {
  border-radius: 24px !important;
}
.radius-22 {
  border-radius: 22px !important;
}
.radius-16 {
  border-radius: 16px !important;
}
.radius-12 {
  border-radius: 12px !important;
}
.radius-10 {
  border-radius: 10px !important;
}
.radius-8 {
  border-radius: 8px !important;
}

.btn-shadow {
  box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.1) !important;
}

.shadow {
  box-shadow: 2px 4px 20px rgba(0, 0, 0, 0.1) !important;
}

.f-12 {
  font-size: 12px !important;
}

.v-messages__message,
.f-14 {
  font-size: 14px !important;
}

.f-16 {
  font-size: 16px !important;
}

.f-17 {
  font-size: 17px !important;
}

.f-20 {
  font-size: 20px !important;
}

.f-22 {
  font-size: 22px;
}

.f-24 {
  font-size: 24px;
}

.f-32 {
  font-size: 32px !important;
}

.theme--light.v-application {
  color: black !important;
}

.v-input .v-input__slot {
  border-radius: 8px !important;
  margin-bottom: 0;
}

.v-application {
  & p {
    margin-bottom: 0 !important;
  }
}

.pointer {
  cursor: pointer;
}
.btn-text {
  font-weight: 700!important;
  text-transform: lowercase;
  &::first-letter {
    text-transform: capitalize;
  }
}

.btn-text-400 {
  font-weight: 400!important;
  text-transform: lowercase;
  &::first-letter {
    text-transform: capitalize;
  }
}

.width100 {
  width: 100% !important;
}
.width0 {
  width: 0;
}
.height100 {
  height: 100%;
}

.auth {
  max-width: 480px;
  width: 100%;
  margin: 0 auto;

  &__description {
    max-width: 400px;
  }
}

.rotate-30 {
  transform: rotate(30deg);
}

.rotate-90 {
  transform: rotate(90deg);
}

.rotate-180 {
  transform: rotate(180deg);
}

.text-center input {
  text-align: center !important;
}

.v-btn__loader {
  color: white !important;
}

.btn-loader--blue .v-btn__loader {
  color: #3f9ed7 !important;
}

.btn-loader--red .v-btn__loader {
  color: #b84545 !important;
}

.btn-loader--grey .v-btn__loader {
  color: var(--v-grey-2-base) !important;
}

.v-menu__content {
  filter: drop-shadow(2px 4px 12px rgba(0, 0, 0, 0.06)) !important;
  box-shadow: none !important;
  border: 1px solid #e5e5e5;
  border-radius: 8px !important;
}

.v-data-footer__select {
  margin-right: initial !important;
  margin-inline-end: auto !important;
  // margin-left: initial !important;
  font-size: 16px;
  .v-input {
    .v-input__slot {
      height: initial !important;
      min-height: initial !important;
    }
  }
}
.v-application--is-ltr {
  .v-data-footer__select {
    margin-left: initial !important;
  }
}

.v-select__selection {
  font-size: 16px !important;
}

.v-data-footer__pagination {
  font-size: 16px !important;
}

.v-pagination {
  .v-pagination__navigation,
  .v-pagination__item {
    box-shadow: none !important;
    .v-icon {
      color: black !important;
    }
  }
}

.v-data-footer__select .v-input__append-inner {
  margin-right: 10px !important;
}
.v-skeleton-loader__avatar {
  margin-right: 16px !important;
}
.v-application--is-rtl {
  .v-data-footer__select .v-input__append-inner {
    margin-right: 20px !important;
  }
}

.v-skeleton-loader__text:last-child {
  margin: 0 !important;
}

.hover-ava {
  align-items: flex-end;
  bottom: 0;
  justify-content: center;
  position: absolute;
  width: 100%;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) 0.01%,
    rgba(4, 4, 4, 0.063) 66.05%,
    rgba(46, 46, 46, 0.7) 100%
  );
}

.info {
  &__wrapper {
    border-bottom: 1px solid #e5e5e5;
    &--disabled {
      border-bottom: none;
    }
  }
  &__form {
    max-width: 560px;
    width: 100%;
  }

  &__section-table {
    .info__section-item {
      min-height: 52px;
      height: max-content;
      display: flex;
      align-items: center;
      background-color: transparent;
      border-bottom: 1px solid var(--v-gray-6-base);

      .info__key {
        color: var(--v-gray-1-base);
        min-width: 350px;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
      }

      .info__value {
        color: #000;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        height: max-content;
      }

      &:last-child {
        border-bottom: none;
      }
    }
  }
}
.loader--center {
  position: absolute;
  left: calc(50% + 120px);
  top: 50%;
  transform: translate(-50%, -50%);
}
.v-application--is-rtl {
  .loader--center {
    left: initial;
    right: calc(50%);
  }
}

.link {
  text-decoration: none;
}

.relative {
  position: relative !important;
}

.gray-field .v-input__slot {
  background: #f4f4f4 !important;
}

// .v-input__append-inner {
//   position: absolute;
//   top: 50%;
//   right: 12px;
//   transform: translateY(-50%);
//   margin: 0 !important;
// }

input {
  padding: 0 !important;
  line-height: 32px !important;
}

.autocomplete {
  overflow: hidden !important;
}

.notification {
  .v-snack__wrapper {
    border: 1px solid #3f9ed7 !important;
    background: #f5fbff !important;
    position: fixed;
    top: 70px !important;
    right: 16px;
  }

  .v-snack__content {
    padding-right: 5px !important;
  }
}
.view-without-scroll {
  overflow: scroll;
  overflow-x: hidden;
  scrollbar-width: none;
}
.view-without-scroll::-webkit-scrollbar {
  width: 0;
  background-color: transparent;
}

.bg-white {
  background-color: white;
}

.opacity-80 {
  opacity: 0.8;
}

.font-weight-700 {
  font-weight: 700;
}

.line-height-150 {
  line-height: 150%
}

.clip {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.v-input.input-white {
  .v-input__slot {
    background-color: white !important;
  }
}

.v-input.input50.input-white.v-text-field--outlined {
  .v-input__slot {
    background-color: white !important;
  }
}


.v-dialog.custom-modal-end {
  position: absolute;
  inset-inline-end: 0;
  top: 0;
  padding: 0;
  margin: 0;
  border-radius: 0!important;
  min-height: 100vh;
}

.w800 {
  font-weight: 800;
}

.w400 {
  font-weight: 400;
}

.w-100 {
  width: 100%;
}

.position-relative {
  position: relative;
}
</style>
